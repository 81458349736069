import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"

import Inner from "../../components/Inner"
import Info from "../../components/Info"
import PostItem from "../../components/PostItem"
import Divider from "../../components/Divider"

const List = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
	grid-gap: 16px;
`

const Content = styled.div`
	padding: 2em 0;
`

const News = styled.section``

const Section = () => {
	const data = useStaticQuery(graphql`
		{
			allStrapiPosts {
				edges {
					node {
						id
						title
						shortDescription
						slug
						postCategory {
							name
						}
						thumbnail {
							publicURL
						}
						createdAt
					}
				}
			}
		}
	`)

	const posts = data.allStrapiPosts.edges

	return (
		<News>
			<Inner>
				<Content>
					<Info title="What's happening?" detail="Keeping up with our lastest updates." />
					<Divider />
					<List>
						{posts.map(item => {
							let { id, title, shortDescription, postCategory, slug, thumbnail, createdAt } = item.node

							const formatDate = createdAt.split("T")[0].replace(/-/g, "/")

							return (
								<Link key={id} to={`${formatDate}/${slug}`}>
									<PostItem
										title={title}
										shortDescription={shortDescription}
										category={postCategory ? postCategory.name : ""}
										imageUrl={thumbnail ? thumbnail.publicURL : ""}
									/>
								</Link>
							)
						})}
					</List>
				</Content>
			</Inner>
		</News>
	)
}

export default Section
