import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"
import { FaKiss } from "react-icons/fa"

import * as Color from "../../utils/color"

const Category = styled.small`
	${tw`text-xs tracking-normal`}

	font-weight: bold;
	text-transform: uppercase;
	justify-self: flex-end;

	color: ${Color.secondary};
`

const NoThumbnail = styled.div`
	width: 100%;
	height: 100%;
	border: solid 1px lightgray;
	background-color: white;
	border-radius: 8px;

	display: flex;
	justify-content: center;
	align-items: center;
`

const Thumbnail = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 8px;
`

const ShortDescription = styled.span`
	font-weight: bold;
	color: lightgray;
`

const Title = styled.h5`
	${tw`text-xl leading-tight`}
	font-weight: bold;
	color: ${Color.primary};
`

const Header = styled.div`
	flex: 2;
`

const ThumbnailContainer = styled.div`
	${tw`mb-3`}
	height: 250px;
	min-height: 250px;
`

const Post = styled.article`
	cursor: pointer;
	height: 100%;
	display: flex;
	flex-direction: column;
`

const PostWrapper = ({ title, shortDescription, category = "untitled", imageUrl, imageAlt, ...props }) => {
	return (
		<Post {...props}>
			<ThumbnailContainer>
				{imageUrl ? (
					<Thumbnail src={imageUrl} alt={imageAlt} />
				) : (
					<NoThumbnail>
						<FaKiss size={32} />
					</NoThumbnail>
				)}
			</ThumbnailContainer>
			<Header>
				<Title>
					{title}
					<ShortDescription>{" " + shortDescription}</ShortDescription>
				</Title>
				<Category>{category}</Category>
			</Header>
		</Post>
	)
}

export default styled(PostWrapper)``
