import React from "react"
import News from "../sections/news"

const Wrapper = () => {
	return (
		<>
			<News />
		</>
	)
}

export default Wrapper
